import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import Banner from '../components/banner'
import Sidebar from '../components/sidebar'
import Seo from '../components/seo'

import SeoImage from '../assets/images/promo-weight-loss.jpg'

class WeightLossCaldwell extends React.Component {
    render() {
        return (
            <StaticQuery
            query={graphql`
                query WeightLossCaldwellMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            emailUrl
                            phone
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout>
                    <Seo title={`Medically Supervised Weight Loss – Caldwell, NJ | ${data.site.siteMetadata.title}`} description={`Medically supervised weight loss in Caldwell helps to guide patients towards healthier weight by combining medication, nutrition counseling, and exercise programs.`} image={SeoImage} keywords={[``]} />
                    <Banner title='Medically Supervised Weight Loss – Caldwell, NJ' subTitle='' titleColor='#FFFFFF' subTitleColor='#FFFFFF' image={SeoImage} />
                    <section>
                        <Container>
                            <Row>
                                <Col xs='12' sm='8' md='8' lg='8'>
                                    <p class='introduction'>For those that have difficulties losing weight by simply eating well and working out, medically supervised weight loss provides a solution. There are many factors in weight loss, including metabolism, health issues and plenty more, and getting proper nutrition counseling and medication could be exactly what is needed for individuals with weight loss difficulties.</p>

                                    <p>Patients with high levels of body fat are at increased risk for many diseases and health issues including heart attacks, strokes, diabetes, and hypertension. Medically supervised weight loss in Caldwell helps to guide patients towards healthier weight by combining medication, nutrition counseling, and exercise programs. These methods have been proven to keep patients on track and reduce body weight and bodyfat to healthy levels.</p>

                                    <h2>Medically Supervised Weight Loss: How does it work?</h2>

                                    <p>The process works by developing a plan for each individual that is custom to their background, journey, and goals. There is no single method that fits every patient, because each person brings unique issues to the table.</p>

                                    <h3>Consultation and Planning</h3>

                                    <p>After contacting an Optimal Health and Wellness doctor in Caldwell, a consultation will be scheduled to discuss medical history, past weight loss efforts and difficulties, goals, and other relevant information to begin an effective medical weight loss journey. We will also look at common potential undiagnosed issues such as thyroid conditions and sleep issues to see if there are contributing factors that may not have been accounted for initially.</p>

                                    <p>Additional testing could potentially be done after an initial consultation in order to determine if any other issues are evident that could factor into the preparation and execution of the medically supervised weight loss plan.</p>

                                    <h3>Meal Replacement and Exercise Programs</h3>

                                    <p>Meal replacement and preparation programs differ for each individual as well as what types of programs certain doctors recommend. Some programs are more loose, involving a general nutrition plan with calorie and macronutrient numbers to hit daily, while others involve a purchased plan of delivered food items daily so that there is no questioning what a patient will eat each day.</p>

                                    <p>Exercise programs will also be discussed and agreed upon. This generally includes a certain amount of light daily exercise and perhaps a few more vigorous exercise sessions per week.</p>

                                    <p>Both meal replacement and exercise programs will be discussed in the initial consultation and planning stage. Insurance coverage may contribute to the plan as well, as some insurance companies provide some level of assistance for medically supervised weight loss.</p>

                                    <h3>Medication for Weight Loss</h3>

                                    <p>For those with difficulties losing weight solely through exercise and dieting, medication for weight loss can be prescribed by your Caldwell doctor. This should increase the likelihood of success and possibly quicken the timeline to meeting the patient’s goals. Medication will be agreed upon and chosen by the patient’s doctor, as there are a variety of options.</p>

                                    <h3>Check-in</h3>

                                    <p>Patients will check in every 3 to 4 weeks until initial weight loss goals are met. An initial goal could be something like losing 10% of their body weight. After this goal is met, check-ins may be farther between. They should not go away completely, however, as obesity is considered a life-long difficulty.</p>

                                    <h3>Results</h3>

                                    <p>Results depend on the individual and their commitment to the medically supervised weight loss program. Many patients see up to 50 pounds lost in 18-24 weeks. Results may vary, and the check-in process is particularly important for adjusting the program accordingly.</p>

                                    <h2>Call Today</h2>

                                    <p>If losing weight has become difficult, and the typical methodologies of dieting and exercise aren’t seeming to get the job done, feel free to give <a href={`tel:${data.site.siteMetadata.phone}`} rel='noopener noreferrer'>{data.site.siteMetadata.title} in Caldwell a call</a>. We can schedule a consultation and start the process to help you lose weight and raise your quality of life today.</p>
                                </Col>
                                <Sidebar title={data.site.siteMetadata.title} phone={data.site.siteMetadata.phone} email={data.site.siteMetadata.emailUrl} hasCare={true} hasContact={true} />
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default WeightLossCaldwell